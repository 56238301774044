<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        <ion-text color="primary"
          ><h2 class="ion-text-center">Was uns ausmacht</h2></ion-text
        ></ion-card-title
      >
      <ion-card-subtitle class="ion-text-center">
        <h3 color="secondary">Unsere Vision</h3></ion-card-subtitle
      >
      <ion-card-title class="ion-text-center">
        <h2 class="ion-text-wrap">
          Geographische Informationslösungen für alle
        </h2></ion-card-title
      >
      <ion-card-subtitle class="ion-text-center">
        <h3>Unsere Werte</h3>
      </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-slides pager="true" :options="slideOpts" scrollbar="true">
        <ion-slide v-for="card in items" :key="card.name" :id="card.name">
          <ion-card>
            <ion-card-subtitle>{{ card.name }}</ion-card-subtitle>
            <ion-img :src="card.img" />
          </ion-card>
        </ion-slide>
      </ion-slides>
    </ion-card-content>

    <ion-card-header>
      <ion-card-title class="ion-text-center"
        ><h2>Unsere Mission</h2></ion-card-title
      >
      <ion-card-subtitle class="ion-text-center">
        <h3 class="ion-text-wrap">
          Wir bereichern Softwaresysteme mit geographischen Komponenten.
        </h3></ion-card-subtitle
      >
    </ion-card-header>
  </ion-card>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonCardSubtitle,
  IonText,
  IonImg,
  IonCard,
  IonSlide,
  IonSlides,
  IonCardTitle,
  IonCardContent,

  IonCardHeader
} from '@ionic/vue';
export default defineComponent({
  name: 'visionMission',
  components: {
    // TransitionCard,
    IonImg,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonCard,
  IonCardHeader,
    IonSlides,
    IonSlide,
    IonText,
  },
  setup() {
    const slideOpts = {
      slidesPerView: 3,
      slidesPerGroup: 3,
      speed: 400,
      spaceBetween: 1,
      autoplay: {
        delay: 5000,
      },
    };
    return { slideOpts };
  },
  data() {
    return {
      items: [
        {
          name: 'Freiheit',
          img: require('@/assets/img/freedom/freedom_640.jpg'),
          imgSize: '(max-width: 640px) 100vw, 640px',
          srcSet: [
            { url: require('@/assets/img/freedom/freedom_200.jpg'), w: '200w' },
            {
              url: require('@/assets/img/freedom/freedom_323.jpg'),
              w: ' 323w',
            },
            {
              url: require('@/assets/img/freedom/freedom_416.jpg'),
              w: ' 416w',
            },
            {
              url: require('@/assets/img/freedom/freedom_491.jpg'),
              w: ' 491w',
            },
            {
              url: require('@/assets/img/freedom/freedom_562.jpg'),
              w: ' 562w',
            },
            {
              url: require('@/assets/img/freedom/freedom_620.jpg'),
              w: ' 620w',
            },
            {
              url: require('@/assets/img/freedom/freedom_640.jpg'),
              w: ' 640w',
            },
          ],
        },
        {
          name: 'Wertschätzung',
          img: require('@/assets/img/wertschaetzung/wertschaetzung_640.jpg'),
          imgSize: '(max-width: 640px) 100vw, 640px',
          srcSet: [
            {
              url: require('@/assets/img/wertschaetzung/wertschaetzung_200.jpg'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/wertschaetzung/wertschaetzung_454.jpg'),
              w: ' 454w',
            },
            {
              url: require('@/assets/img/wertschaetzung/wertschaetzung_640.jpg'),
              w: ' 640w',
            },
          ],
        },
        {
          name: 'Vertrauen',
          img: require('@/assets/img/trust/trust_640.jpg'),
          imgSize: '(max-width: 640px) 100vw, 640px',
          srcSet: [
            {
              url: require('@/assets/img/trust/trust_200.jpg'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/trust/trust_302.jpg'),
              w: ' 302w',
            },
            {
              url: require('@/assets/img/trust/trust_386.jpg'),
              w: ' 386w',
            },
            {
              url: require('@/assets/img/trust/trust_460.jpg'),
              w: ' 460w',
            },
            {
              url: require('@/assets/img/trust/trust_523.jpg'),
              w: ' 523w',
            },
            {
              url: require('@/assets/img/trust/trust_586.jpg'),
              w: ' 586w',
            },
            {
              url: require('@/assets/img/trust/trust_640.jpg'),
              w: ' 640w',
            },
          ],
        },
        {
          name: 'Loyalität',
          img: require('@/assets/img/loyality/loyality_640.jpg'),
          imgSize: '(max-width: 640px) 100vw, 640px',
          srcSet: [
            {
              url: require('@/assets/img/loyality/loyality_200.jpg'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/loyality/loyality_353.jpg'),
              w: ' 353w',
            },
            {
              url: require('@/assets/img/loyality/loyality_467.jpg'),
              w: ' 467w',
            },

            {
              url: require('@/assets/img/loyality/loyality_602.jpg'),
              w: ' 602w',
            },
            {
              url: require('@/assets/img/loyality/loyality_640.jpg'),
              w: ' 640w',
            },
          ],
        },
        {
          name: 'Freude',
          img: require('@/assets/img/codehappy/codehappy_640.jpg'),
          imgSize: '(max-width: 640px) 100vw, 640px',
          srcSet: [
            {
              url: require('@/assets/img/codehappy/codehappy_200.jpg'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/codehappy/codehappy_578.jpg'),
              w: ' 578w',
            },
            {
              url: require('@/assets/img/codehappy/codehappy_640.jpg'),
              w: ' 640w',
            },
          ],
        },
        {
          name: 'Geographie',
          img: require('@/assets/img/gis/gis_640.jpg'),
          imgSize: '(max-width: 640px) 100vw, 640px',
          srcSet: [
            {
              url: require('@/assets/img/gis/gis_200.jpg'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/gis/gis_419.jpg'),
              w: ' 419w',
            },
            {
              url: require('@/assets/img/gis/gis_560.jpg'),
              w: ' 560w',
            },
            {
              url: require('@/assets/img/gis/gis_640.jpg'),
              w: '640w',
            },
          ],
        },

        {
          name: 'Strukturiert',
          img: require('@/assets/img/kanbanboard/kanbanboard_1128.png'),
          imgSize: '(max-width: 1128px) 100vw, 1128px',
          srcSet: [
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_200.png'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_320.png'),
              w: ' 320w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_411.png'),
              w: ' 411w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_491.png'),
              w: ' 491w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_557.png'),
              w: ' 557w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_617.png'),
              w: ' 617w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_671.png'),
              w: ' 671w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_722.png'),
              w: ' 722w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_774.png'),
              w: ' 774w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_814.png'),
              w: ' 814w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_857.png'),
              w: ' 857w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_899.png'),
              w: ' 899w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_944.png'),
              w: ' 944w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_981.png'),
              w: ' 981w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_1021.png'),
              w: ' 1021w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_1058.png'),
              w: ' 1058w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_1095.png'),
              w: ' 1095w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_1126.png'),
              w: ' 1126w',
            },
            {
              url: require('@/assets/img/kanbanboard/kanbanboard_1128.png'),
              w: ' 1128w',
            },
          ],
        },
        {
          name: 'Teilen',
          img: require('@/assets/img/juergen/juergen_762.jpg'),
          imgSize: '(max-width: 762px) 100vw, 762px',
          srcSet: [
            {
              url: require('@/assets/img/juergen/juergen_200.jpg'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/juergen/juergen_354.jpg'),
              w: ' 354w',
            },
            {
              url: require('@/assets/img/juergen/juergen_471.jpg'),
              w: ' 471w',
            },
            {
              url: require('@/assets/img/juergen/juergen_568.jpg'),
              w: ' 568w',
            },
            {
              url: require('@/assets/img/juergen/juergen_661.jpg'),
              w: ' 661w',
            },
            {
              url: require('@/assets/img/juergen/juergen_762.jpg'),
              w: ' 762w',
            },
          ],
        },
        {
          name: 'Geerdet',
          img: require('@/assets/img/austria/austria_640.jpg'),
          imgSize: '(max-width: 640px) 100vw, 640px',
          srcSet: [
            {
              url: require('@/assets/img/austria/austria_200.jpg'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/austria/austria_440.jpg'),
              w: ' 440w',
            },
            {
              url: require('@/assets/img/austria/austria_613.jpg'),
              w: ' 613w',
            },
            {
              url: require('@/assets/img/austria/austria_640.jpg'),
              w: ' 640w',
            },
          ],
        },
      ],
    };
  },
  methods: {
    toSrcSetString(sizesArray) {
      let concatFct = (accum, red) =>
        accum.concat(red.url).concat(' ').concat(red.w).concat(',');
      sizesArray.reduce(concatFct, '');
    },
  },
});
</script>

<style scoped>
ion-img::part(image) {
  height: 400px;
  max-width: 3000px;
  display: block;
  object-fit: cover;
}
ion-item {
  --highlight-height: 70%;
  --min-height: 200px;
}

ion-title {
  padding: 20px;
  font-size: 1.9em;
}

ion-card-subtitle {
  font-size: 1.3em;
  padding: 10px;
}

ion-card-title {
  padding: 10px;
  font-size: 1.9em;
}

ion-card-content {
  margin: 10px;
  font-size: 1.2em;
}

p {
  margin: 10px;
  padding: 10px;
}
</style>