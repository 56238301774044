<template>
  <hr />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'spacerHr',
});
</script>

<style scoped>
hr {
  border: none;
  position: relative;
 padding-bottom: 50px;
}
hr::before {
  background-color: rgba(0, 128, 128, 0.8);
  content: '';
  display: block;
  height: 3px;
  right: 0;
  position: absolute;
  width: 40%;
  z-index: 1;
}
</style>