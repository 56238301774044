<template>
  <ion-page>
    <ion-content fullscreen>
<ion-card>
  <ion-title color="primary">
    <ion-text class="ion-text-start">Impressum</ion-text>
  </ion-title>

  <ion-text><h3>Firmenwortlaut:</h3></ion-text>
  <p><ion-text>GISolutions e.U – Dr. Jürgen Hahn </ion-text></p>

  <ion-text><h3>Unternehmensgegenstand:</h3></ion-text>
  <p >
    Konzeption, Begleitung und Implementierung von geographischen
    Informationslösungen. <br />
    Unternehmensberatung einschließlich der Unternehmensorganisation.
  </p>

  <ion-text><h3>UID-Nummer:</h3></ion-text>
  <strong>ATU73864614</strong>

  <ion-text><h3>Firmenbuchnummer:</h3></ion-text>
  <strong> 503269 b Handelsgericht Wien </strong>

  <ion-text><h3>Firmensitz:</h3></ion-text>
  <ul>
    <li>Adresse : Hadikgasse 78 Top 2</li>
    <li>A - 1140 | Wien</li>
    <li>Mobile : +43 664 55 31 682</li>
    <li>E-mail : office@gisolutions.at</li>
  </ul>

  <ion-button
    href="https://www.wkoecg.at/WEB/Ecg.aspx?FirmaID=e7ab9994-4d27-4565-a9d1-1e3832b42637"
    target="_blank"
  >
    Link zur WKO
  </ion-button>
  </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage,IonContent,IonButton, IonTitle, IonText,IonCard } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'compImpressum',
  components: {
    IonButton,
    IonPage,
    IonContent,
    IonTitle,
    IonText,
    IonCard
  },
});
</script>

<style scoped>
</style>