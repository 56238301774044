<template>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button >
        GISolutions Home
        </ion-button>

      </ion-buttons>
      <ion-buttons slot="end">
        <!-- <ion-button >
          Anwendungsgebiete
        </ion-button>
        <ion-button >
          Individuallösungen
        </ion-button>
        <ion-button >
          OpenGIS as a Service
        </ion-button>
        <ion-button >
          GISolutions
        </ion-button> -->
        <ion-button href="./karriere">
          Karriere
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <!-- <section>
    <ion-header no-border>
      <ion-toolbar>
        <ion-title>Home</ion-title> -->
  <!-- <router-link :to="{ name: 'home', hash: '#showcase' }">
          <img
            :src="require('@/assets/firmenlogo/LogoBig.svg')"
            alt="zurück zur Hauptseite"
          />
        </router-link> -->
  <!-- </ion-toolbar> -->

  <!-- <ion-toolbar> -->
  <!-- <ion-title>Anwendungsgebiete</ion-title>
        <router-link :to="{ name: 'home', hash: '#anwendungsgebiete' }">
          Anwendungsgebiete
        </router-link> -->
  <!-- </ion-toolbar>
    </ion-header> -->

  <!-- <v-app-bar fixed>
      <router-link :to="{ name: 'home', hash: '#showcase' }">
        <v-toolbar-title>
          <v-img
            :src="require('@/assets/firmenlogo/LogoBig.svg')"
            alt="zurück zur Hauptseite"
          ></v-img>
        </v-toolbar-title>
      </router-link>

      <v-spacer></v-spacer> -->

  <!-- Anwendungsgebiete btn-->
  <!-- <v-btn text class="headertext">
        <router-link :to="{ name: 'home', hash: '#anwendungsgebiete' }">
          Anwendungsgebiete
        </router-link>
      </v-btn> -->

  <!-- Individual Lösungen menu-->
  <!-- <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="headertext">
            Individuallösungen
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in individualLoesungen" :key="i">
            <v-list-item-title>
              <router-link :to="toRouterLinkObjekt(item)">
                {{ item.title }}
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->

  <!-- OpenGIS as a Service menu-->

  <!-- <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="headertext">
            OpenGIS as a Service
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in opengisService" :key="i">
            <v-list-item-title>
              <router-link :to="toRouterLinkObjekt(item)">
                {{ item.title }}
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
 -->
  <!-- GISolutions menu-->
  <!-- <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="headertext">
            GISolutions
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in gisolutions" :key="i">
            <v-list-item-title>
              <router-link :to="toRouterLinkObjekt(item)">
                {{ item.title }}
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text class="headertext">
        <router-link :to="{ name: 'karriere' }"> Karriere </router-link>
      </v-btn>
    </v-app-bar> -->
  <!-- </section> -->
</template>

<script>
import { IonHeader, IonToolbar, IonButtons, IonButton } from "@ionic/vue";
// import { defineAsyncComponent } from '@vue/runtime-core';
import { defineComponent } from 'vue';


import {
  personCircle,
} from 'ionicons/icons';

export default defineComponent({
  name: "appHeader",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton
  },
  setup(){
    return {
      personCircle
    }
  },

  data() {
    return {
      drawer: false,
      individualLoesungen: [
        { title: "Beratung", routername: "home", routerhash: "#beratung" },
        {
          title: "Dienstleistung",
          routername: "home",
          routerhash: "#dienstleistungen",
        },
      ],
      opengisService: [
        {
          title: "OpenGIS as a Service",
          routername: "home",
          routerhash: "#opengisasaservice",
        },
        {
          title: "Technologien",
          routername: "home",
          routerhash: "#technologien",
        },
        {
          title: "Open WebGIS",
          routername: "home",
          routerhash: "#webgis_product",
        },
        {
          title: "Open GeoServices",
          routername: "home",
          routerhash: "#open_geoservices",
        },
        {
          title: "Kunden und Partner",
          routername: "home",
          routerhash: "#kunden",
        },
      ],
      gisolutions: [
        {
          title: "Philosophie",
          routername: "home",
          routerhash: "#philosophie",
        },
        {
          title: "visionMission",
          routername: "home",
          // routerhash: "#visionMission" ?
          routerhash: "#vision-mission-werte",
        },
        {
          title: "Social Media",
          routername: "home",
          routerhash: "#social-media",
        },
        { title: "Kontakt", routername: "home", routerhash: "#call-to-action" },
      ],
    };
  }
});
</script>

<style scoped>
</style>