<template>
<ion-card>
<ion-card-header>
  <ion-card-title class="ion-text-center">
    <ion-text color="primary"
      ><h2 class="ion-text-wrap">
        GISolutions bietet unter anderem für folgende Anwendungsgebiete Lösungen
        an
      </h2></ion-text
    >
  </ion-card-title>

    <ion-card-subtitle class="ion-text-center">
      <ion-text class="ion-text-wrap">
      Unser Lösungsansatz basiert auf dem ersten Gesetz der Geographie von Waldo
      Tobler: <br />
      <span>
        „Everything is related to everything else, but near things are more
        related than distant things.“
      </span></ion-text
  >
    </ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>

  <ion-grid>
    <ion-row class="ion-align-items-start ion-justify-content-start">
      <ion-col
        size="6"
        size-xs="12"
        size-sm="12"
        size-md="6"
        size-lg="6"
        size-xl="6"
        v-for="topic in dataObject.content"
        :key="topic.id"
      >
        <ion-card>
          <ion-img :src="topic.imgUrl" ></ion-img>

          <ion-card-title class="ion-text-center">{{
            topic.title
          }}</ion-card-title>
          <ion-card-subtitle class="ion-text-center">{{
            topic.description
          }}</ion-card-subtitle>
          <ion-card-content>
            <ul v-for="element in topic.offers" :key="element">
              <li>{{ element }}</li>
            </ul>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardTitle,
   IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'compAnwendungsgebiete',
  components: {
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
     IonCardHeader,
    IonCardContent,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      dataObject: {
        content: [
          {
            id: 'umwelt',
            title: 'Umwelt',
            description:
              'Uns liegt die Umwelt am Herzen. Um diese für unsere Kinder zu erhalten, unterstützen wir Sie mit:',
            offers: [
              'Visualisierung und Analyse von Geodaten, die Bäume oder Pflanzen zeigen, zum Beispiel für Projektentscheidungen',
              'Visualisierung und Analyse von menschlich erzeugten Faktoren wie C02-Emissionen, illegale Mülldeponien, Lärmaufkommen',
              'Visualisierung und Analyse der Beschaffenheit von Geländen, zum Beispiel um Gründe für Bodenerosion aufzuzeigen',
            ],
            imgUrl: require('@/assets/img/umwelt/umwelt_640.jpg'),
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgAltText:
              'GISolutions unterstützt die Umwelt, indem wir diese transparent visualisieren und die Daten analysieren.',
            imgSrcSet: [
              {
                url: require('@/assets/img/umwelt/umwelt_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/umwelt/umwelt_387.jpg'),
                w: ' 387w',
              },
              {
                url: require('@/assets/img/umwelt/umwelt_526.jpg'),
                w: ' 526w',
              },
              {
                url: require('@/assets/img/umwelt/umwelt_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'infrastruktur',
            title: 'Infrastruktur',
            description:
              'Wir unterstützen Sie bei der Digitalisierung Ihrer Infrastruktur',
            offers: [
              'Datenaufnahme und Speicherung eines "digital twin (digitalen Zwillings)" Ihrer Infrastuktur inkl. Fotoprotokoll, z.B. für die Fragestellung: Wann wurde das Objekt das letzte mal gereinigt/inspiziert?',
              'Verknüpfung von Echtzeit-Sensordaten mit Geodaten zur Visualisierung und Optimierung',
              'Erkennung von Optimierungspotential durch automatisierte Analysen basierend auf geographischer Distanz',
            ],
            imgUrl: require('@/assets/img/infrastructure/infrastructure_640.jpg'),
            imgAltText:
              'GISolutions bietet Dienstleistungen für verteilte, wartungsintensive Infrastrukturen an.',
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgSrcSet: [
              {
                url: require('@/assets/img/infrastructure/infrastructure_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_282.jpg'),
                w: ' 282w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_350.jpg'),
                w: ' 350w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_410.jpg'),
                w: ' 410w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_468.jpg'),
                w: ' 468w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_516.jpg'),
                w: ' 516w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_568.jpg'),
                w: ' 568w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_621.jpg'),
                w: ' 621w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'nachhaltigkeit',
            title: 'Nachhaltigkeit',
            description:
              'Wir unterstützen Sie bei der Visualisierungen und Analyse zum Thema Nachhaltigkeit, zum Beispiel bei folgenden Fragestellungen:',
            offers: [
              'Wie groß darf die Waldfläche sein, die geschlagen wird, sodass die Bäume darauf permanent nachwachsen?',
              'Welche Wegstrecke haben Lebensmittel bisher zurückgelegt?',
              'In welcher Relation steht die Anzahl der Schulen eines Gebietes zur Anzahl der Einwohner?',
            ],
            imgUrl: require('@/assets/img/sustainability/sustainability_640.jpg'),
            imgAltText:
              'GISolutions setzt sich für Nachhaltigkeit ein, indem wir Geodaten (GPS) analysieren und visualisieren',
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgSrcSet: [
              {
                url: require('@/assets/img/sustainability/sustainability_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/sustainability/sustainability_289.jpg'),
                w: ' 289w',
              },
              {
                url: require('@/assets/img/sustainability/sustainability_364.jpg'),
                w: ' 364w',
              },
              {
                url: require('@/assets/img/sustainability/sustainability_422.jpg'),
                w: ' 422w',
              },
              {
                url: require('@/assets/img/sustainability/sustainability_475.jpg'),
                w: ' 475w',
              },
              {
                url: require('@/assets/img/sustainability/sustainability_529.jpg'),
                w: ' 529w',
              },
              {
                url: require('@/assets/img/sustainability/sustainability_579.jpg'),
                w: ' 579w',
              },
              {
                url: require('@/assets/img/sustainability/sustainability_635.jpg'),
                w: ' 635w',
              },
              {
                url: require('@/assets/img/infrastructure/infrastructure_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'gpsdatenanalyse',
            title: 'GPS Daten',
            description:
              'Wir decken den kompletten Lebenszyklus von GPS-Daten ab. Beispiele für GPS-Datenlösungen:',
            offers: [
              'Wo, wann und in welchen Herden bewegen sich Wildtiere?',
              'Wo und wann gibt es Hotspots?',
              'Welche externen Faktoren zeigen einen Einfluss mit den GPS-Daten, zum Beispiel: Wind, Regen, Sonne, Lärm?',
            ],
            imgUrl: require('@/assets/img/gps_data/gps-data_640.jpg'),
            imgAltText:
              'GISolutions speichert, visualisiert und analysiert GPS-Daten. ',
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgSrcSet: [
              {
                url: require('@/assets/img/gps_data/gps-data_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/gps_data/gps-data_340.jpg'),
                w: ' 340w',
              },
              {
                url: require('@/assets/img/gps_data/gps-data_448.jpg'),
                w: ' 448w',
              },
              {
                url: require('@/assets/img/gps_data/gps-data_536.jpg'),
                w: ' 536w',
              },
              {
                url: require('@/assets/img/gps_data/gps-data_620.jpg'),
                w: ' 620w',
              },
              {
                url: require('@/assets/img/gps_data/gps-data_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'geomarketing',
            title: 'Geomarketing',
            description:
              'Wir unterstützen Sie dabei, die geographische Dimension in Ihren Marketing-Mix einzubinden. Dabei beantworten wir unter anderem folgende Fragestellungen:',
            offers: [
              'Woher kommen meine Kunden? Wo sind mögliche Neukunden?',
              'An welchem Ort befinden sich viele Objekte einer bestimmten Art?',
              'Sind Kunden und Vertriebsgebiete geographisch optimal aufgeteilt?',
            ],
            imgUrl: require('@/assets/img/geomarketing/geomarketing_640.jpg'),
            imgAltText:
              'GISolutions unterstützt Sie dabei, die geographische Dimension in Ihren Marketing-Mix einzubinden.',
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgSrcSet: [
              {
                url: require('@/assets/img/geomarketing/geomarketing_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/geomarketing/geomarketing_344.jpg'),
                w: ' 344w',
              },
              {
                url: require('@/assets/img/geomarketing/geomarketing_457.jpg'),
                w: ' 457w',
              },
              {
                url: require('@/assets/img/geomarketing/geomarketing_554.jpg'),
                w: ' 554w',
              },
              {
                url: require('@/assets/img/geomarketing/geomarketing_620.jpg'),
                w: ' 620w',
              },
              {
                url: require('@/assets/img/geomarketing/geomarketing_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'standortwahl',
            title: 'Standortwahl',
            description:
              'Wir unterstützen Sie bei der Wahl eines neuen Standortes für ein Lager, eine Außenstelle, einen Partner etc.',
            offers: [
              'Wo eröffne ich einen neuen Standort?',
              'Wo ist ein Standort für ein Zwischenlager profitabel?',
              'In welchem Gebiet wird ein zusätzlicher Händler benötigt?',
            ],
            imgUrl: require('@/assets/img/standort/standort_640.jpg'),
            imgAltText:
              'GISolutions findet mit Ihnen den optimalen Betriebsstandort.',
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgSrcSet: [
              {
                url: require('@/assets/img/standort/standort_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/standort/standort_412.jpg'),
                w: ' 412w',
              },
              {
                url: require('@/assets/img/standort/standort_620.jpg'),
                w: ' 620w',
              },
              {
                url: require('@/assets/img/standort/standort_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'erreichbarkeit',
            title: 'Mobilität',
            description:
              'GISolutions unterstützt Sie im Bereich Mobilität durch angepasste Routingdienste und Analysen, um zum Beispiel folgende Fragen zu beantworten:',
            offers: [
              'Wie viele Kunden/Baustellen können an einem Tag erreicht werden?',
              'Welches Montageteam trifft am schnellsten am Ort des Störungsfalls ein?',
              'Wie können die Routen der Montageteams optimiert werden, um wenig Zeit mit der Anfahrt zu verlieren?',
            ],
            imgUrl: require('@/assets/img/erreichbar/eta_640.jpg'),
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgAltText:
              'GISolutions hilft Ihnen, Ihre Außendienstmitarbeiter optimal einzusetzen.',
            imgSrcSet: [
              {
                url: require('@/assets/img/erreichbar/eta_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/erreichbar/eta_354.jpg'),
                w: ' 354w',
              },
              {
                url: require('@/assets/img/erreichbar/eta_473.jpg'),
                w: ' 473w',
              },
              {
                url: require('@/assets/img/erreichbar/eta_575.jpg'),
                w: ' 575w',
              },
              {
                url: require('@/assets/img/erreichbar/eta_638.jpg'),
                w: ' 638w',
              },
              {
                url: require('@/assets/img/erreichbar/eta_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'webgis',
            title: 'WebGIS',
            description:
              'Wir erzeugen Visualisierungen Ihrer Geodaten im Web, um Ergebnisse einem großen Publikum zur Verfügung stellen zu können',
            offers: [
              'Visualisierung von geographischen Zusammenhängen',
              'Visualisierung von öffentlichen Daten, zum Beispiel:',
              'Visualisierung von Studienergebnissen',
            ],
            imgUrl: require('@/assets/img/webapp/webapp_668.jpg'),
            imgSize: '(max-width: 668px) 100vw, 668px',
            imgAltText:
              'GISolutions hilft Ihnen, Ihre GPS-, GIS- und Geodaten ansprechend zu visualisieren.',
            imgSrcSet: [
              {
                url: require('@/assets/img/webapp/webapp_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/webapp/webapp_368.jpg'),
                w: ' 368w',
              },
              {
                url: require('@/assets/img/webapp/webapp_494.jpg'),
                w: ' 494w',
              },
              {
                url: require('@/assets/img/webapp/webapp_629.jpg'),
                w: ' 629w',
              },
              {
                url: require('@/assets/img/webapp/webapp_668.jpg'),
                w: ' 668w',
              },
              {
                url: require('@/assets/img/erreichbar/eta_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'geodatenverarbeitung',
            title: 'Geodatenmanagement',
            description:
              'Wir managen den kompletten Geodatenzyklus für Sie und sorgen darüber hinaus dafür, dass Ihre Geodaten für ML und AI aufbereitet werden. Dienstleistungen dafür gliedern sich in:',
            offers: [
              'Datenbereinigung und Datenanreicherung mit Metadaten',
              'Verbesserung der Datenqualität, Duplikate finden und filtern',
              'Verknüpfungen bzw. Zusammenführung von Daten aus mehreren Datenquellen',
            ],
            imgUrl: require('@/assets/img/geodata_progress.png'),
            imgSize: '(max-width: 668px) 100vw, 668px',
            imgAltText: 'GISolutions hilft Ihnen, Ihre Geoadten zu veredeln.',
            imgSrcSet: [],
          },
        ],
      },
    };
  },
});
</script>

<style scoped>
ion-img::part(image) {
  height: 200px; /* or whatever value */
  display: block;
  object-fit: cover;
}

ion-col {
  padding: 10px;
}

ion-title {
  padding: 20px;
  font-size: 1.9em;
}


ion-card-subtitle{
  font-size: 1.3em;
  padding: 10px;
}

ion-card-title{
  padding: 10px;
  font-size: 1.9em;
}

ion-card-content{
  margin: 10px;
  font-size: 1.2em;
}

p {
  font-size: 1.5em;
  margin:10px;
  padding: 10px;
}


</style>
