<template>

  <ion-card>
<ion-card-header>
<ion-card-title class="ion-text-center">
    <ion-text color="primary"
      ><h2 class="ion-text-wrap">Unsere Philosphie</h2></ion-text>

  </ion-card-title>
</ion-card-header>
    <ion-card-content>
        <!-- <h3 class="h3-title text-center">Über uns</h3> -->
        <ion-text >
          <span >
          <strong>GISolutions</strong> setzt sich aus kreativen ExpertInnen
          zusammen, die die Leidenschaft der Geographie und Informatik teilen
          und Lösungen für viele Disziplinen und Herausforderungen erstellen.</span>
        </ion-text>
        <br /><br />
        <ion-text class="ion-text-center">
          Wir erzeugen geographische Informationslösungen in einer Form, dass
          diese ohne zusätzliche Beschreibungen eingängig für alle Nutzer verwendbar sind. Mit
          der Erweiterung um die geographische Dimension entstehen neue
          Möglichkeiten, zum Beispiel geographische
          <strong>Analysen und Datenvisualisierungen</strong> in Karten, die es
          Nutzern und Entscheidungsträgern ermöglichen, Zusammenhänge schnell zu
          erfassen und gezielt Erkenntnisse abzuleiten.
        </ion-text><br /><br />
        <ion-text class="ion-text-center">
          Technologisch sind wir dem <strong>OpenSource</strong> Gedanken
          verhaftet. <strong>Alle Technologien</strong>, die wir verwenden, fassen wir unter
          dem Schlagwort <strong>OpenGIS</strong> zusammen.
        </ion-text>
        </ion-card-content>
</ion-card>
</template>

<script>
  import { defineComponent } from 'vue';
import {
  IonText,
  IonCard,
  IonCardContent,
  IonCardHeader,
 IonCardTitle
} from '@ionic/vue';
export default defineComponent( {
  name: 'compPhilosophie',
  components: {
    IonText,
    IonCard,
    IonCardContent,
IonCardHeader,
IonCardTitle
  },
} );
</script>

<style scoped>



ion-title {
  padding: 20px;
  font-size: 1.9em;
}

ion-card-title{
  padding: 10px;
  font-size: 1.9em;
}

ion-card-content{
  margin: 10px;
  font-size: 1.2em;
}

p {
  font-size: 1.1em;
  margin:10px;
  padding: 10px;
}

</style>