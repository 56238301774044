<template>
  <div style="background-color: #f4f4f4;padding:10px">
    <ion-grid>
      <ion-row class="ion-align-items-start ion-justify-content-center">
        <ion-col
          size="12"
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-lg="12"
          size-xl="8"
        >
          <ion-card>
            <ion-card-title class="ion-text-center" color="primary">
              Weil alles einen Ort hat!
            </ion-card-title>
            <ion-card-subtitle class="ion-text-center"
              ><span class="ion-text-wrap">
                Wo Ihre GIS-Lösung gedacht und gebaut wird, zuhause ist,
                gepflegt wird und Ihnen Zeit erspart!
              </span></ion-card-subtitle
            >
            <ion-img :src="call2ActionImgUrl"> </ion-img>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonCard,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/vue';
export default defineComponent({
  name: 'ShowCase',
  components: {
    IonCardSubtitle,
    IonCardTitle,
    IonCard,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      showcaseImgUrl: {
        original: require('@/assets/img/showcase/showcase_1400.jpg'),
        imgsrcSet: [
          {
            url: require('@/assets/img/showcase/showcase_200.jpg'),
            w: ' 200w',
          },
          {
            url: require('@/assets/img/showcase/showcase_428.jpg'),
            w: ' 428w',
          },
          {
            url: require('@/assets/img/showcase/showcase_598.jpg'),
            w: ' 598w',
          },
          {
            url: require('@/assets/img/showcase/showcase_751.jpg'),
            w: ' 751w',
          },
          {
            url: require('@/assets/img/showcase/showcase_863.jpg'),
            w: ' 863w',
          },
          {
            url: require('@/assets/img/showcase/showcase_984.jpg'),
            w: ' 1086w',
          },
          {
            url: require('@/assets/img/showcase/showcase_1086.jpg'),
            w: ' 1180w',
          },
          {
            url: require('@/assets/img/showcase/showcase_1180.jpg'),
            w: ' 1269w',
          },
          {
            url: require('@/assets/img/showcase/showcase_1357.jpg'),
            w: ' 1357w',
          },
          {
            url: require('@/assets/img/showcase/showcase_1397.jpg'),
            w: ' 1397w',
          },
          {
            url: require('@/assets/img/showcase/showcase_1400.jpg'),
            w: ' 1400w',
          },
        ],
      },
      call2ActionImgUrl: require('@/assets/img/cta/cta_640.jpg'),
      imgSrcSet: [
        {
          url: require('@/assets/img/cta/cta_200.jpg'),
          w: ' 200w',
        },
        {
          url: require('@/assets/img/cta/cta_377.jpg'),
          w: ' 377w',
        },
        {
          url: require('@/assets/img/cta/cta_514.jpg'),
          w: ' 514w',
        },
        {
          url: require('@/assets/img/cta/cta_627.jpg'),
          w: ' 627w',
        },
        {
          url: require('@/assets/img/cta/cta_640.jpg'),
          w: ' 640w',
        },
      ],
    };
  },
  methods: {
    toSrcSetString(sizesArray) {
      let concatFct = (accum, red) =>
        accum.concat(red.url).concat(' ').concat(red.w).concat(',');
      sizesArray.reduce(concatFct, '');
    },
  },
});
</script>

<style scoped>
ion-img::part(image) {
  height: 70%;
  width: 100%;
  max-width: 3000px;
  display: block;
  object-fit: cover;
}


ion-card-subtitle {
  padding: 10px;
  font-size: 1.3em;
}

ion-card-title {
  padding: 10px;
}

ion-card-content {
  margin: 10px;
}

ion-card-title{
  font-size: 1.9em;
}

</style>