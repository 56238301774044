<template>
<ion-card style="background-color: #f4f4f4;padding:10px">
 <ion-card-header>
  <ion-card-title class="ion-text-center">
    <ion-text color="primary"><h2>OpenGIS as a Service</h2></ion-text></ion-card-title>
    <ion-card-subtitle class="ion-text-center">
      <ion-text class="ion-text-wrap">
      Wir bilden den kompletten Geodaten-Lebenszyklus mit Open Source Werkzeugen
      ab und betreiben diese für Sie.</ion-text>
    </ion-card-subtitle>

</ion-card-header>
<ion-card-content>
  <ion-grid>
    <ion-row class="ion-align-items-start ion-justify-content-center">
      <ion-col
        size="6"
        size-xs="12"
        size-sm="12"
        size-md="4"
        size-lg="4"
        size-xl="4"
      >
        <ion-card>
          <ion-card-title class="ion-text-center"
            ><h3 class="ion-text-wrap">
              Wann wird OpenGIS as a Service eingesetzt?
            </h3></ion-card-title>
          <ion-card-content>
            <ul>
              <li>
                Wenn Softwareanforderungen einen starken geographischen Bezug
                aufweisen und geographische Expertise benötigt wird.
              </li>
              <li>
                Wenn individuelle Lösungen abseits großer Hersteller benötigt
                werden.
              </li>
              <li>
                Wenn große Mengen an geographischen Daten verwaltet,
                transformiert und im Web präsentiert werden müssen.
              </li>
              <li>
                Wenn Know-how oder Zeit für GIS-Analysen oder das
                Geodatenmanagement fehlt.
              </li>
            </ul></ion-card-content
          >
        </ion-card>
      </ion-col>
      <ion-col
        size="6"
        size-xs="12"
        size-sm="12"
        size-md="3"
        size-lg="3"
        size-xl="3"
      >
        <ion-card>
          <ion-img :src="openGISImgUrl" />
        </ion-card>
      </ion-col>

      <ion-col
        size="6"
        size-xs="12"
        size-sm="12"
        size-md="4"
        size-lg="4"
        size-xl="4"
      >
        <ion-card>
          <ion-card-title class="ion-text-center">
            <h3>Vorteile:</h3></ion-card-title
          >
          <ion-card-content>
            <ul>
              <li>Keine Kosten bei der Geodatenbeschaffung oder Nutzung</li>
              <li>Individualisierte Dienste angepasst auf Ihre Anforderungen</li>
              <li>
                Schnelle Umsetzung und Übertragbarkeit an andere Dienstleister
                durch Nutzung von offenen Standards und dokumentierten
                Werkzeugen.
              </li>
            </ul>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card-content>
</ion-card>
</template>

<script>
import { defineComponent } from 'vue';
import {  IonText, IonImg, IonGrid, IonRow, IonCol,IonCardHeader,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle
   } from '@ionic/vue';
export default defineComponent({
  name: 'OpenGISservice',
  components: {
    IonImg,
    IonGrid,
    IonText,
    IonRow,
    IonCol,
    IonCardHeader,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle

  },
  data() {
    return {
      openGISImgUrl: require('@/assets/img/opengis/OpenGIS_435.png'),
      services: [
        {
          service:
            'Geografische Dienste as a Service (Open Source Applikationen und frei verfügbaren Daten) angepasst an Ihre Bedürfnisse ',
        },
        { service: 'Geo-Datenmodellierung' },
        { service: 'Geo-Datenspeicherung in Datenbanken' },
        { service: 'Geodatenmanagement' },
        { service: 'Analysen von Geodaten' },
        {
          service:
            'Individuelle Routingdienste mit täglichen Datenaktualisierungen',
        },
        { service: 'Geocoding mit täglichen Datenaktualisierungen' },
        { service: 'Visualisierung  von Geodaten im Web und mobile' },
      ],
    };
  },
});
</script>

<style scoped>
ion-img::part(image) {
  height: 400px;
  width: 100%;
  max-width: 3000px;
  display: block;
  object-fit: cover;
}
ion-col {
  padding: 10px;
}

ion-title {
  padding: 20px;
  font-size: 1.9em;
}


ion-card-subtitle{
  font-size: 1.3em;
  padding: 10px;
}

ion-card-title{
  padding: 10px;
  font-size: 1.9em;
}

ion-card-content{
  margin: 10px;
  font-size: 1.2em;
}

p {

  margin:10px;
  padding: 10px;
}

</style>