<template>
  <ion-card>
    <ion-card-header>
    <ion-card-title class="ion-text-center">
      <ion-text color="primary"
        ><h2 class="h2-title text-center">Follow Us</h2></ion-text
      >
    </ion-card-title>

    </ion-card-header>
    <ion-card-content>
    <ion-grid>
      <ion-row class="ion-align-items-center ion-justify-content-between">
        <ion-col
          size="3"
          size-xs="3"
          size-sm="3"
          size-md="3"
          size-lg="3"
          size-xl="3"
        >
          <ion-button fill="outline" expand="block" size="large" href="https://twitter.com/solutions_gi" target="_blank"
              rel="noreferrer">
            Twitter
              <ion-icon slot="start" :icon="a"></ion-icon>
              <!-- <ion-icon src="../../assets/icons/logo-twitter.svg"></ion-icon> -->

          </ion-button>
        </ion-col>
        <ion-col
          size="3"
          size-xs="3"
          size-sm="3"
          size-md="3"
          size-lg="3"
          size-xl="3"
        >
          <ion-button fill="outline" expand="block" size="large"  href="https://www.linkedin.com/company/gisolutions-e-u/"
              target="_blank"
              rel="noreferrer">
            LinkedIn
              <ion-icon slot="start" :icon="logo - linkedin"></ion-icon>

          </ion-button>
        </ion-col>
        <ion-col
          size="3"
          size-xs="3"
          size-sm="3"
          size-md="3"
          size-lg="3"
          size-xl="3"
        >
          <ion-button fill="outline" expand="block" size="large" href="https://github.com/GISolutionsAT"
              target="_blank"
              rel="noreferrer">
            Github
              <ion-icon slot="start" name="logo-github"></ion-icon>

          </ion-button>
        </ion-col>
        <ion-col
          size="3"
          size-xs="3"
          size-sm="3"
          size-md="3"
          size-lg="3"
          size-xl="3"
        >
          <ion-button fill="outline" expand="block" size="large" href="https://www.xing.com/companies/gisolutionse.u."
              target="_blank"
              rel="noreferrer">
              Xing
              <ion-icon slot="icon-only" name="logo-xing"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid>
      <ion-row class="ion-align-items-center ion-justify-content-between">
        <ion-col
          size="4"
          size-xs="4"
          size-sm="4"
          size-md="4"
          size-lg="4"
          size-xl="4"
        >
          <ion-card>
            <ion-card-content class="ion-text-center"> <ion-text>Hadikgasse 78 Top 2, 1140 Wien</ion-text> </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col
          size="4"
          size-xs="4"
          size-sm="4"
          size-md="4"
          size-lg="4"
          size-xl="4"
        >
          <ion-card>
            <ion-card-content class="ion-text-center">
              <a href="tel:+436645531682">+436645531682</a>
            </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col
          size="4"
          size-xs="4"
          size-sm="4"
          size-md="4"
          size-lg="4"
          size-xl="4"
        >
          <ion-card>
            <ion-card-content class="ion-text-center">
              <a href="mailto:office@gisolutions.at">office@gisolutions.at</a>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
    </ion-card-content>
  </ion-card>
  <p></p>
  <p></p>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButton, IonIcon, IonRow, IonCol, IonGrid,IonCard, IonCardContent,IonText,IonCardHeader,
    IonCardTitle, } from '@ionic/vue';

export default defineComponent({
  name: 'socialMedia',
  components: {
    IonButton,
    IonCard,
    IonIcon,
    IonRow,
    IonCol,
    IonGrid,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
IonText
  },
  data() {
    return {
      selectedIcon: '',
    };
  },
  methods: {
    setValue: function (icon) {
      this.selectedIcon = icon;
    },
  },
  watch: {
    selectedIcon: function (val) {
      this.$gtag.event('select_content', {
        event_category: 'engagement',
        event_label: `SocialMedia-${val}`,
        value: 0,
      });
    },
  },
});
</script>

<style scoped>

myback {
  padding-bottom: 400px;
}

ion-col {
  padding: 10px;
}

ion-title {
  padding: 20px;
  font-size: 1.9em;
}

ion-card-subtitle {
  font-size: 1.3em;
  padding: 10px;
}

ion-card-title {
  padding: 10px;
  font-size: 1.9em;
}

ion-card-content {
  margin: 10px;
  font-size: 1.2em;
}

p {
  margin: 10px;
  padding: 10px;
}
</style>