
import { defineComponent } from 'vue';
import {

  IonText,
  IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  IonImg,
  IonCardHeader,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle
  // IonPopover,
  // IonContent,
  // IonButton
} from '@ionic/vue';
export default defineComponent({
  name: 'compTechnologien',
  components: {
    IonList,
    //     IonButton,
    //     IonPopover,
    // IonContent,
    IonCardHeader,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
    IonImg,
    IonItem,
    IonAvatar,
    IonLabel,

    IonText,
  },
  data() {
    return {
      actualSelected: 'osm',
      content: {
        osm: {
          id: 'osm',
          name: 'OSM',
          title: 'Open Street Map (OSM) als Daten- und Werkzeuglieferant',
          description:
            'Wir alle profitieren von freien Daten, Verarbeitungswerkzeugen und einer aktiven Community. Wir bei GISolutions pflegen eine aktive Zusammenarbeit mit der Community in Österreich und unterstützen Sie mit folgenden Dienstleistungen:',
          offers: [
            'Unterstützung beim Umstieg von kostspieligen Webmapping Bibliotheken auf OSM-basierte Alternativen',
            'Schulungen zum OSM-Datenmodell und dem Lebenszyklus von Onlinekartenprodukten',
            'Verwendung von OSM-Daten in Geschäftsprozessen, zum Beispiel bei GIS-Analysen',
            `Nutzung von OSM-Werkzeugen in Geschäftsprozessen. Mehr dazu unter:`,
          ],
          imgUrl: require('@/assets/img/osm_logo/osm__245.png'),
          imgSize: '(max-width: 245px) 100vw, 245px',
          imgAltText:
            'GISolutions erzeugt Lösungen und teilt Wissen rund um OpenStreetMap.',
          imgSrcSet: [
            {
              url: require('@/assets/img/osm_logo/osm__200.png'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/osm_logo/osm__245.png'),
              w: ' 245w',
            },
          ],
        },
        qgis: {
          id: 'qgis',
          name: 'QGIS',
          title: 'OpenSource Desktop Informationswerkzeug QGIS',
          description: `Das OpenSource GIS Desktopwerkzeug für Analysen und Geodatemanegement. GISolutions nutzt QGIS täglich für Raster- (GDAL) und Vektordatenverarbeitung sowie GIS-Analysen. Nach dem Motto "Sharing is caring" teilen wir unser Know-how gerne.`,
          offers: [
            'Schulungen angepasst an Ihre Bedürfnisse',
            'Unterstützung bei der Einführung von Prozessen für Ihre Geschäftsprozesse',
            'Prototypentest, ob QGIS die Anforderungen erüllt',
            'Überführung von QGIS-Projekten in Web-GIS-Systeme',
          ],
          imgUrl: require('@/assets/img/qgis_logo/qgis_238.png'),
          imgSize: '(max-width: 238px) 100vw, 238px',
          imgAltText: 'GISolutions erzeugt Lösungen mit QGIS.',
          imgSrcSet: [
            {
              url: require('@/assets/img/qgis_logo/qgis_200.png'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/qgis_logo/qgis_238.png'),
              w: ' 238w',
            },
          ],
        },
        osrm: {
          id: 'osrm',
          name: 'OSRM',
          title: 'Individueller Routingdienst basierend auf OSM-Daten',
          description:
            'GISolutions erzeugt Routingdienste angepasst an Ihre Bedürfnisse. Als Software nutzen wir die Open Source Software OSRM, ein in C implementierter Routingdienst designed für High performance Bedürfnisse, der weltweit eingesetzt wird.',
          offers: [
            'Anpassung des Routing-Algorithmus für Autos, Fußgänger oder andere Transportationsmodi',
            'Nutzung von tagesaktuellen OSM-Daten als Vorteil gegenüber anderen Diensten',
            'Erweiterungen mit Höhendaten oder verknüpften Geschäftsdaten',
          ],
          imgUrl: require('@/assets/img/osrm/osrm_423.png'),
          imgSize: '(max-width: 423px) 100vw, 423px',
          imgAltText:
            'GISolutions erzeugt individuelle Routinglösungen mit OSRM',
          imgSrcSet: [
            {
              url: require('@/assets/img/osrm/osrm_200.png'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/osrm/osrm_423.png'),
              w: ' 423w',
            },
          ],
        },
        photon: {
          id: 'photon',
          name: 'Photon',
          title: 'Individueller Geocoding Dienst basierend auf OSM Daten',
          description:
            'Der Beginn vieler Anwendungen ist die Überführung einer Adresse in eine Koordinate. Der Geocoding-Algorithmus von Photon nutzt die Möglichkeiten von Elasticsearch für Fuzzy Searching in OSM Data. GISolutions passt photon an Ihre Bedürfnisse an',
          offers: [
            'Nutzung von tagesaktuellen Daten von OSM, als Vorteil gegenüber anderen verfügbaren Diensten',
            'Verfügbarkeit von vielen Sprachen',
          ],
          imgUrl: require('@/assets/img/photon/photon_506.png'),
          imgSize: '(max-width: 506px) 100vw, 506px',
          imgAltText:
            'GISolutions erzeugt individuelle Geocodinglösungen mit Photon',
          imgSrcSet: [
            {
              url: require('@/assets/img/photon/photon_200.png'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/photon/photon_428.png'),
              w: ' 428w',
            },
            {
              url: require('@/assets/img/photon/photon_506.png'),
              w: ' 506w',
            },
          ],
        },
        postgis: {
          id: 'postgis',
          name: 'PostGIS',
          title: 'Open Source Geodatenbank PostGIS',
          description:
            'In der Open Source Geodatenbank PostGIS verwalten wir Geodaten verknüpft mit Geschäftsdaten. Das aufgebaute Wissen teilen wir gerne:',
          offers: [
            'Datenimport von Geodaten mit OpenSource Werkzeugen: shp2pgsql, osm2pgsql, raser2pgsql',
            'Aufsetzen und Einrichten von PostGIS für Ihre Umgebung, zum Beispiel Verbindungen zu Desktop GIS einrichten',
            'Verwaltung Ihrer Daten und anpassen an Ihre Bedürfnisse mittels Views und Triggers',
            'Bereitstellen der Daten über REST-Schnittstellen für die Verarbeitung im Web-Umfeld',
          ],
          imgUrl: require('@/assets/img/postgis/postgis_150.jpg'),
          imgSize: '(max-width: 150px) 100vw, 150px',
          imgAltText: 'GISolutions speichert Geodaten mit PostGIS.',
          imgSrcSet: [
            {
              url: require('@/assets/img/postgis/postgis_150.jpg'),
              w: ' 150w',
            },
          ],
        },
        ol: {
          id: 'ol',
          name: 'OpenLayers',
          title: 'OpenSource Web Mapping Bibliothek',
          description:
            'OpenLayers ist die Nummer 1 für OpenSource Web Mapping und bietet einen großen Funktionsumfang. GISolutions nutzt OpenLayers in plain JavaScript Webprojekten oder in Verbindung mit OpenSource Frameworks, zum Beispiel: GeoEXT, Wegue, etc. Darüber hinaus sind unsere Angebote:',
          offers: [
            'Unterstützung beim Umstieg von kostspieligen Webmapping Bibliotheken auf OpenLayers',
            'Schulungen für Ihre Mitarbeiter im Umgang mit OpenLayers (Koordinatensysteme, Datenformate, etc.)',
            'WebGIS-Implementierungen mit OpenLayers.',
          ],
          imgUrl: require('@/assets/img/ol_logo/ol_291.png'),
          imgSize: '(max-width: 291px) 100vw, 291px',
          imgAltText:
            'GISolutions nutzt Open Layers für Kartenvisualisierungen.',
          imgSrcSet: [
            {
              url: require('@/assets/img/ol_logo/ol_200.png'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/ol_logo/ol_291.png'),
              w: ' 291w',
            },
          ],
        },
        docker: {
          id: 'docker',
          name: 'Docker',
          title: 'Umsetzungen in Containertechnologie',
          description:
            'Docker bietet die Möglichkeit, standardisierte Images zu erstellen, mit den Vorteilen von Skalierbarkeit und Ausfallsicherheit. GISolutions nutzt diese Technologien als Basis für die anderen Open Source Technologien und teilt das Wissen gerne.',
          offers: [
            'Betrieb von Geo-Diensten und WebGIS-Umsetzungen on-premise oder in der Cloud',
            'Bereitstellung von Daten im Vector Tiles Format, mehr dazu unter:',
            'Bereitstellen und Anpassen von Geodatendiensten für alle Prozessen der Softwareentwicklung, zum Beispiel die lokale Ausführung auf Rechnern von Entwicklern',
          ],
          imgUrl: require('@/assets/img/docker_logo/docker_logo_279.png'),
          imgSize: '(max-width: 279px) 100vw, 279px',
          imgAltText: 'GISolutions nutzt Docker um Lösungen umzusetzen.',
          imgSrcSet: [
            {
              url: require('@/assets/img/docker_logo/docker_logo_200.png'),
              w: ' 200w',
            },
            {
              url: require('@/assets/img/docker_logo/docker_logo_279.png'),
              w: ' 279w',
            },
          ],
        },
      },
    };
  },
});
