<template>
  <ion-footer >
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button href="./privacy">
            Datenschutzerklärung
            </ion-button>
      </ion-buttons>
      <ion-title class="ion-text-center"
              >created with Vue.js by GISolutions in
              {{ new Date().getFullYear() }}</ion-title
            >
      <ion-buttons slot="end">
        <ion-button href="./impressum">
              Impressum
            </ion-button>
      </ion-buttons>

      <!-- <ion-title>created with Vue.js by GISolutions in {{ new Date().getFullYear() }}</ion-title> -->
    </ion-toolbar>
  </ion-footer>
  <!-- Text unterneath Icons -->
  <!-- <div class="footer-text">
      <ion-button router-link="/privacy">Datenschutzerklärung</ion-button>
      <router-link  :to="{name: 'privacy'}">
        <strong>Datenschutzerklärung</strong>
      </router-link> -->

  <!-- <div class="footer-join-us">
        <strong>  created with Vue.js by GISolutions in {{ new Date().getFullYear() }} </strong>
      </div>

      <ion-button router-link="/impressum"> -->
  <!-- <ion-button href="/impressum"> -->
  <!-- <strong>Impressum</strong>
      </ion-button>
     -->
  <!-- <router-link  :to="{name: 'impressum'}">
        <strong>Impressum</strong>
      </router-link> -->
  <!-- </div>
  </footer> -->
</template>

<script>
// import router from '../../router';
import {
  IonFooter,
  IonToolbar,
  IonTitle,
  IonButton,

} from "@ionic/vue";
// import { defineAsyncComponent } from '@vue/runtime-core';
// import { defineComponent } from '@vue/runtime-core';
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
  components: {
    IonButton,
    IonFooter,
    IonToolbar,
    IonTitle,

    // IonContent,
    // IonPage,
  },
});
</script>

<style scoped>
/* .footer-text {
    display: flex;
    justify-content: space-around;
    background-color: #333;
    color: #fff;
  }

  .footer-text a {
    text-decoration: none;
    color: #fff;
  }

  .footer-text a:hover {
    text-decoration: none;
    color: #2aa198;
  } */

/* Smartphones */
/* @media (max-width: 500px) {
    .footer-join-us {
      display: none;
    }
  } */
</style>