<template>
  <ion-page>
    <ion-content>
 <ion-card>
    <h2 class="h2-title">
      Karriere
    </h2>
    <!-- <p>
      Derzeit sind wir nicht auf der Suche nach Verstärkung. Für Diplomarbeiten
      und Ähnliches können Sie uns jedoch jederzeit eine Anfrage senden unter
      <a href="mailto:office@gisolutions.at">office@gisolutions.at</a>.
    </p> -->
    <h3 class="h3-title">Aktuelle Ausschreibungen</h3>

    <p style='text-align: center;  font-size: 150%; color: teal; font-weight: 600;'>
      <br>JavaScript Entwickler (w/m/x) Full Stack<br>Teilzeit 20-25h/Woche<br>Wien<br>
    </p>
    <p>
      Die Java Script Entwicklung ist deine Leidenschaft und du möchtest deine Kenntnisse unter Beweis stellen? Du stellst
      den Status-quo gerne in Frage, findest Lösungen und möchtest dich fachlich weiterentwickeln?
      <br><br>
      Wir, die GISolutions, suchen zur Verstärkung des Softwareentwicklungsteams einen Mitarbeiter (w/m/x) für die
      Implementierung von webbasierten/mobilen (geografischen) Informationssystemen.
    </p>
    <p>
      <strong>Deine Aufgaben:</strong>
      <ul>
        <li>
      Unterstützung bei (inter-) nationalen Kundenprojekten</li>
      <li>Entwicklung und Umsetzung von Kundenanforderungen mit OpenSource Webtechnologien (Node.js, Vue.js)</li>
      <li>Möglichkeit zur Weiterentwicklung in andere Rollen oder Technologien</li>
      <li>Aufbau von Expertenwissen im Bereich OpenSource GIS (OpenLayers)</li>
      </ul>
    </p>

    <p>
      <strong>Das bringst du mit:</strong>
      <ul>
        <li>
      Technische Ausbildung (HTL, FH, Uni) mit IT Fokus</li>
      <li>Erste Erfahrung in der Softwareentwicklung, Umgang mit Git</li>
      <li>Kenntnisse von JavaScript basierten Technologien, idealerweise Erfahrungen mit Node.js</li>
      <li>Know-how in Containertechnologien wünschenswert</li>
      <li>Selbständige Arbeitsweise, Neugierde und Lernbereitschaft</li>
      <li>Interesse an geografischen Informationssystemen</li>
      </ul>
    </p>

    <p>
      <strong>Das erwartet dich:</strong>
      <ul>
        <li>Tolle Bürogemeinschaft in einem historischen Gebäude</li>
        <li>Eine ausgeprägte Lern- und Feedback-Kultur, flexible Zeit- und Ortseinteilung</li>
        <li>Junges humorvolles Team in einem aufstrebenden Markt</li>


      </ul>
    </p>
    <p>
      Die Vergütung erfolgt nach Kollektivvertrag im Bereich Dienstleistungen in der automatischen Datenverarbeitung und
      Informationstechnik. Für diese Stelle ist ein Vollzeitgehalt von 2.428 Euro brutto pro Monat vorgesehen, mit der
      Bereitschaft zur Überzahlung, abhängig von beruflicher Qualifikation und Erfahrung.
      <br><br>
      Bitte sende deine aussagekräftige Bewerbung an:
      <a href='mailto:office@gisolutions.at'>office@gisolutions.at</a>
    </p>
    </ion-card>
    </ion-content>
  </ion-page>



</template>

<script>
import { IonPage, IonContent, IonCard } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'compKarriere',
  components: {
    IonPage,
    IonContent,

    IonCard,
  },
});
</script>

<style scoped>
</style>