
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import CompPhilosophie from '../components/homeSections/Philosophie.vue';
import CompAnwendungsgebiete from '../components/homeSections/Anwendungsgebiete.vue';
import appHeader from '../components/layout/AppHeader.vue';
import CompBeratung from '../components/homeSections/Beratung.vue';
// import CompCall2Action from '../components/homeSections/CalltoAction.vue';
import CompDienstleistungen from '../components/homeSections/Dienstleistungen.vue';
import KundenPartner from '../components/homeSections/KundenPartner.vue';
// import OpenGeoServices from '../components/homeSections/OpenGeoServices.vue';
import OpenGisService from '../components/homeSections/OpenGISservice.vue';
import ShowCase from '../components/homeSections/ShowCase.vue';
import SocialMedia from '../components/homeSections/SocialMedia.vue';
import CompTechnologien from '../components/homeSections/Technologien.vue';
// import CompUmsetzung from '../components/homeSections/Umsetzung.vue';
import VisionMission from '../components/homeSections/VisionMission.vue';
import SpacerRight from '../components/layout/SpacerRight.vue';
import SpacerLeft from '../components/layout/SpacerLeft.vue';
// import AppFooter from '../components/layout/AppFooter.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    appHeader,
    IonContent,
    SpacerRight,
    SpacerLeft,
    IonPage,
    CompPhilosophie,
    CompAnwendungsgebiete,
    CompBeratung,
    // CompCall2Action,
    CompDienstleistungen,
    KundenPartner,
    // OpenGeoServices,
    OpenGisService,
    ShowCase,
    SocialMedia,
    CompTechnologien,
    // CompUmsetzung,
    VisionMission,
    // AppFooter
  },
});
