<template>
  <ion-card style="background-color: #f4f4f4;padding:10px">
    <ion-card-header>

  <ion-card-title class="ion-text-center">
    <ion-text color="primary"
      ><h2 class="ion-text-wrap">
        GISolutions bietet das Komplettpaket für individuelle Lösungen
      </h2></ion-text
    >
  </ion-card-title>

  </ion-card-header>
 <ion-card-content>
  <ion-grid>
    <ion-row class="ion-align-items-start ion-justify-content-start">
      <ion-col
        size="6"
        size-xs="12"
        size-sm="12"
        size-md="6"
        size-lg="6"
        size-xl="6"
        v-for="topic in dataObject.content"
        :key="topic.id"
      >
        <ion-card>
          <ion-img :src="topic.imgUrl"></ion-img>

          <ion-card-title class="ion-text-center">{{
            topic.title
          }}</ion-card-title>
          <ion-card-subtitle class="ion-text-center">{{
            topic.description
          }}</ion-card-subtitle>
          <ion-card-content>
            <ul v-for="element in topic.offers" :key="element">
              <li>{{ element }}</li>
            </ul>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  </ion-card-content>
</ion-card>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader
} from '@ionic/vue';

export default defineComponent({
  name: 'compDienstleistungen',
  components: {
    IonCard,
    IonCardTitle,
    IonCardContent,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    IonCardHeader
  },
  data() {
    return {
      dataObject: {
        content: [
          {
            id: 'Anforderungsanalyse',
            title: 'Anforderungsanalyse',
            description:
              'Anforderungen an geographische Informationssysteme sind vielschichtig und benötigen umfangreiches Wissen im Bezug auf Daten, Schnittstellen, Services, Recht etc. Wir bieten Ihnen folgende Dienstleistungen, die z.B. für die Erstellung eines Spezifikationsdokuments für Ausschreibungen oder als Backlog für Ihre agiles Entwicklungsteam dienen können, an:',
            offers: [
              'Analyse von Anforderungen hinsichtlich Geodaten, Geschäftsdaten und deren Verknüpfung',
              'Analyse von Anforderungen und Aufteilung in schätzbare Größen: Epics, Features, User Stories',
              'Umfeldanalyse bezüglich Organisation und IT-Architektur',
              'Dokumentation der Anforderungen in elektronischen Systemen, Wiki, JIRA, etc.',
            ],
            imgUrl: require('@/assets/img/analysis/analysis_640.jpg'),
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgAltText: 'GISolutions bietet Anforderungsanalyse.',
            imgSrcSet: [
              {
                url: require('@/assets/img/analysis/analysis_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/analysis/analysis_502.jpg'),
                w: ' 502w',
              },
              {
                url: require('@/assets/img/analysis/analysis_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'Evaluierung',
            title:
              'Evaluierung bestehender Applikationen oder Alternativapplikationen',
            description:
              'Lizenzkosten von Applikationen müssen in Relation zum Nutzen und den Geschäftsanforderungen stehen. Um diese Relation zu evaluieren, bietet GISolutions folgende Dienstleistungen an:',
            offers: [
              'Evaluierung bestehender Applikationen anhand der Erfüllung von Anforderungen',
              'Kosten-Nutzen Analyse von Applikationen',
              'Aufzeigen von alternativen Applikationen zur Erfüllung der Anforderungen',
            ],
            imgUrl: require('@/assets/img/evaluation/evaluation_640.jpg'),
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgAltText:
              'GISolutions evaluiert Umsetzungsmöglichkeiten und bestehende Systeme.',
            imgSrcSet: [
              {
                url: require('@/assets/img/evaluation/evaluation_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/evaluation/evaluation_416.jpg'),
                w: ' 416w',
              },
              {
                url: require('@/assets/img/evaluation/evaluation_586.jpg'),
                w: ' 586w',
              },
              {
                url: require('@/assets/img/evaluation/evaluation_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'Konzeption',
            title: 'Konzeption von individuellen GIS-Lösungen',
            description:
              'Bei der kostenintensiven Umsetzung von GIS-Lösungen spielen viele Faktoren eine Rolle. GISolutions garantiert zukunftssichere Lösungen durch:',
            offers: [
              'Abgestimmte Prozessen und freigegebene Mockups, UI, UX und Workflows',
              'Intuitive, benutzerfreundliche Oberflächen',
              'Betreueung der Applikation nach der Einführungsphase, Wartung, Betriebsführung',
              'Offenheit zu anderen Systemen unter Nutzung von OGC-Services',
              'Dokumentierte Schnittstellen',
            ],
            imgUrl: require('@/assets/img/concept/concept_640.jpg'),
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgAltText:
              'GISolutions konzipiert Ihre Applikation basierend auf Ihren Anforderungen.',
            imgSrcSet: [
              {
                url: require('@/assets/img/concept/concept_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/concept/concept_399.jpg'),
                w: ' 399w',
              },
              {
                url: require('@/assets/img/concept/concept_546.jpg'),
                w: ' 546w',
              },
              {
                url: require('@/assets/img/concept/concept_640.jpg'),
                w: ' 640w',
              },
            ],
          },
          {
            id: 'Projektmanagement',
            title: 'Projektmanagement mit mehrjähriger Erfahrung',
            description:
              'In der Umsetzungsphase stehen wir Ihnen mit dem aufgebauten Wissen der vorhergehenden Phasen zur Verfügung und dienen als Übersetzer zwischen Anforderungen und Umsetzung.',
            offers: [
              'Projektmanagement für die Einführung von IT-Systemen',
              'Agiles oder klassisches Projektmanagement',
              'Risikoanalyse und Umsetzung nach dem MoSCoW-Ansatz',
              'Projektcontrolling in elektronischen Systemen (JIRA, Onepoint Project, MS Project)',
            ],
            imgUrl: require('@/assets/img/projectmanagement/projectmanagement_640.jpg'),
            imgSize: '(max-width: 640px) 100vw, 640px',
            imgAltText: 'GISolutions bietet Projektmanagement.',
            imgSrcSet: [
              {
                url: require('@/assets/img/projectmanagement/projectmanagement_200.jpg'),
                w: ' 200w',
              },
              {
                url: require('@/assets/img/projectmanagement/projectmanagement_494.jpg'),
                w: ' 494w',
              },
              {
                url: require('@/assets/img/projectmanagement/projectmanagement_640.jpg'),
                w: ' 640w',
              },
            ],
          },
        ],
      },
    };
  },
});
</script>

<style scoped>
ion-img::part(image) {
  height: 200px; /* or whatever value */
  display: block;
  object-fit: cover;
}

ion-col {
  padding: 10px;
}

ion-title {
  padding: 20px;
  font-size: 1.9em;
}


ion-card-subtitle{
  font-size: 1.3em;
  padding: 10px;
}

ion-card-title{
  padding: 10px;
  font-size: 1.9em;
}

ion-card-content{
  margin: 10px;
  font-size: 1.2em;
}

p {
  font-size: 1.5em;
  margin:10px;
  padding: 10px;
}

</style>