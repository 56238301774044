import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { createRouter, createWebHistory } from 'vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
// import CompPhilosophie  from '../components/homeSections/Philosophie.vue';
// import CompAnwendungsgebiete from '../components/homeSections/Anwendungsgebiete.vue';
// import CompBeratung from '../components/homeSections/Beratung.vue';
// import CompDienstleistungen from '../components/homeSections/Dienstleistungen.vue';
// import KundenPartner from '../components/homeSections/KundenPartner.vue';
// import OpenGeoServices from '../components/homeSections/OpenGeoServices.vue';
// import OpenGisService from '../components/homeSections/OpenGISservice.vue';
// import ShowCase from '../components/homeSections/ShowCase.vue';
// import SocialMedia from '../components/homeSections/SocialMedia.vue';
// import CompTechnologien from '../components/homeSections/Technologien.vue';
// import CompUmsetzung from '../components/homeSections/Umsetzung.vue';
// import VisionMission from '../components/homeSections/VisionMission.vue';
import Karriere from '../components/pages/Karriere.vue';
import Impressum from '../components/pages/Impressum.vue';
import Privacy from '../components/pages/Privacy.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  // NICHT BENÖTIGT
  // {
  //   path: '/anwendungsgebiete',
  //   name: 'compAnwendungsgebiete',
  //   component: CompAnwendungsgebiete
  // },
  // {
  //   path: '/philosophie',
  //   name: 'compPhilosophie',
  //   component: CompPhilosophie
  // },
  // {
  //   path: '/beratung',
  //   name: 'compBeratung',
  //   component: CompBeratung
  // },
  // {
  //   path: '/social-media',
  //   name: 'socialMedia',
  //   component: SocialMedia
  // },
  // {
  //   path: '/dienstleistungen',
  //   name: 'compDienstleistungen',
  //   component: CompDienstleistungen
  // },
  // {
  //   path: '/kunden',
  //   name: 'kundenPartner',
  //   component: KundenPartner
  // },
  // {
  //   path: '/open_geoservices',
  //   name: 'OpenGeoServices',
  //   component: OpenGeoServices
  // },
  // {
  //   path: '/opengisasaservice',
  //   name: 'OpenGISservice',
  //   component: OpenGisService
  // },
  // {
  //   path: '/technologien',
  //   name: 'compTechnologien',
  //   component: CompTechnologien
  // },
  // {
  //   path: '/umsetzung',
  //   name: 'compUmsetzung',
  //   component: CompUmsetzung
  // },
  // {
  //   path: '/vision',
  //   name: 'visionMission',
  //   component: VisionMission
  // }

  // BENÖTIGT
  {
    path: '/impressum',
    name: 'compImpressum',
    component: Impressum
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/karriere',
    name: 'Karriere',
    component: Karriere
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
