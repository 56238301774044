import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_show_case = _resolveComponent("show-case")!
  const _component_comp_anwendungsgebiete = _resolveComponent("comp-anwendungsgebiete")!
  const _component_spacer_left = _resolveComponent("spacer-left")!
  const _component_comp_technologien = _resolveComponent("comp-technologien")!
  const _component_spacer_right = _resolveComponent("spacer-right")!
  const _component_comp_Beratung = _resolveComponent("comp-Beratung")!
  const _component_comp_Dienstleistungen = _resolveComponent("comp-Dienstleistungen")!
  const _component_kunden_partner = _resolveComponent("kunden-partner")!
  const _component_open_gis_service = _resolveComponent("open-gis-service")!
  const _component_comp_philosophie = _resolveComponent("comp-philosophie")!
  const _component_vision_mission = _resolveComponent("vision-mission")!
  const _component_social_media = _resolveComponent("social-media")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_app_header),
          _createVNode(_component_show_case),
          _createVNode(_component_comp_anwendungsgebiete),
          _createVNode(_component_spacer_left),
          _createVNode(_component_comp_technologien),
          _createVNode(_component_spacer_right),
          _createVNode(_component_comp_Beratung),
          _createVNode(_component_spacer_left),
          _createVNode(_component_comp_Dienstleistungen),
          _createVNode(_component_spacer_right),
          _createVNode(_component_kunden_partner),
          _createVNode(_component_spacer_left),
          _createVNode(_component_open_gis_service),
          _createVNode(_component_spacer_right),
          _createVNode(_component_comp_philosophie),
          _createVNode(_component_spacer_left),
          _createVNode(_component_vision_mission),
          _createVNode(_component_spacer_right),
          _createVNode(_component_social_media)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}