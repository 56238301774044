<template>
  <ion-card>
    <ion-card-header>
  <ion-card-title class="ion-text-center">
    <ion-text color="primary"
      ><h2 class="ion-text-wrap">Ausgewählte Partner und Kunden</h2></ion-text
    ></ion-card-title>
    <ion-card-subtitle class="ion-text-center">
      <ion-text class="ion-text-wrap">
      Unsere Dienstleistungen finden von der Industrie bis zur Verwaltung
      Einsatz, wir beliefern Unternehmen vom Startup bis zum KMU.
      </ion-text>
    </ion-card-subtitle>

  </ion-card-header>
  <ion-card-content>
<p></p>
  <ion-slides pager="true" :options="slideOpts" scrollbar="true">
    <ion-slide
      :id="content[item].id"
      v-for="item in Object.keys(content).sort()"
      :key="content[item].id"
    >
      <ion-card>
        <a :href="`${content[item].linkUrl}`">
          <ion-img :src="`${content[item].imgUrl}`"
        /></a>
        <ion-card-header> </ion-card-header>
        <ion-card-content> </ion-card-content>
      </ion-card>
    </ion-slide>
  </ion-slides>
  </ion-card-content>
  </ion-card>
</template>

<script>
// import TransitionCard from "../layout/TransitionCard.vue";
import { defineComponent } from 'vue';
import {

  IonText,
  IonCard,
  IonCardHeader,
  IonImg,
  IonCardContent,
  IonSlide,
  IonSlides,
  IonCardSubtitle,
  IonCardTitle

} from '@ionic/vue';
export default defineComponent({
  name: 'kundenPartner',
  components: {
    // TransitionCard,
    IonCard,
    IonCardHeader,
    IonCardContent,

    IonSlides,
    IonSlide,
    IonText,
    IonImg,

  IonCardSubtitle,
  IonCardTitle

  },
  setup() {
    const slideOpts = {
      slidesPerView: 3,
      slidesPerGroup: 3,
      speed: 400,
      spaceBetween: 1,
      autoplay: {
        delay: 5000,
      },
    };
    return { slideOpts };
  },
  data() {
    return {
      content: {
        covid_19: {
          id: 'covid_19',
          title: 'COVID 19',
          description: 'covid_19-comment',
          imgUrl: require('@/assets/img/customers/covid19_logo.png'),
          linkUrl: 'https://www.covid19info.at',
        },
        vorarlberg: {
          id: 'vorarlberg',
          title: 'Vorarlbergtourismus',
          description: 'vorarlberg-comment',
          imgUrl: require('@/assets/img/customers/vt_logo.png'),
          linkUrl: 'https://www.vorarlberg.travel',
        },
        metgis: {
          id: 'metgis',
          title: 'MetGIS',
          description: 'MetGIS-comment',
          imgUrl: require('@/assets/img/customers/metgis_logo.png'),
          linkUrl: 'https://www.metgis.com/de/',
        },
        s_franzke: {
          id: 's_franzke',
          title: 'Schubert & Franzke',
          description: 's_franzke-comment',
          imgUrl: require('@/assets/img/customers/s_f_logo.png'),
          linkUrl: 'https://www.schubert-franzke.com',
        },
        windwerkstatt: {
          id: 'windwerkstatt',
          title: 'Windwerkstatt',
          description: 'windwerkstatt-comment',
          imgUrl: require('@/assets/img/customers/windwerkstatt.png'),
          linkUrl: 'https://windwerkstatt.at',
        },
        ebcont: {
          id: 'ebcont',
          title: 'ebcont',
          description: 'ebcont-comment',
          imgUrl: require('@/assets/img/customers/ebcont_logo.png'),
          linkUrl: 'https://www.ebcont.com',
        },
        w3geo: {
          id: 'w3geo',
          title: 'W3GEO',
          description: 'W3GEO-comment',
          imgUrl: require('@/assets/img/customers/w3geo_logo.png'),
          linkUrl: 'https://w3geo.at',
        },
        geoinfotuwien: {
          id: 'geoinfotuwien',
          title: 'Geoinfo TU Wien',
          description: 'tu_wien-comment',
          imgUrl: require('@/assets/img/customers/geoinfo_logo.png'),
          linkUrl: 'https://geoinfo.geo.tuwien.ac.at',
        },
        meggsimum: {
          id: 'meggsimum',
          title: 'Meggsimum',
          description: 'meggsimum-comment',
          imgUrl: require('@/assets/img/customers/meggsimum_logo.png'),
          linkUrl: 'https://meggsimum.de',
        },
        osm_verein: {
          id: 'osm_verein',
          title: 'OSM Verein Österreich',
          description: 'osm_verein-comment',
          imgUrl: require('@/assets/img/customers/osm_logo.png'),
          linkUrl: 'https://www.openstreetmap.at',
        },
        evva: {
          id: 'evva',
          title: 'EVVA',
          description: 'evva-comment',
          imgUrl: require('@/assets/img/customers/evva_logo.png'),
          linkUrl: 'https://www.evva.com/',
        },
        toursprung: {
          id: 'toursprung',
          title: 'toursprung',
          description: 'Toursprung-comment',
          imgUrl: require('@/assets/img/customers/toursprung_logo.png'),
          linkUrl: 'https://www.toursprung.com/',
        },
        fh_neustadt: {
          id: 'fh_neustadt',
          title: 'Fachhochschule Neustadt',
          description: 'Fachhochschule Neustadt-comment',
          imgUrl: require('@/assets/img/customers/fh_neustadt.png'),
          linkUrl: 'https://www.fhwn.ac.at',
        },
        zuehlke: {
          id: 'zuehlke',
          title: 'Zühlke',
          description: 'zuehlke-comment',
          imgUrl: require('@/assets/img/customers/zuehlke_logo.png'),
          linkUrl: 'https://www.zuehlke.com/de',
        },
        qgis_verein: {
          id: 'qgis_verein',
          title: 'QGIS Österreich',
          description: 'qgis_verein-comment',
          imgUrl: require('@/assets/img/customers/qgis_at_logo.png'),
          linkUrl: 'https://qgis.at',
        },
        transnetbw: {
          id: 'transnetbw',
          title: 'TRANSNET BW',
          description: 'TransnetBW-comment',
          imgUrl: require('@/assets/img/customers/transnetbw.png'),
          linkUrl: 'https://www.transnetbw.de/de',
        }
      },
    };
  },
});
</script>

<style scoped>
ion-img::part(image) {
  height: 200px; /* or whatever value */
  display: block;
  object-fit: contain;
}

ion-title {
  padding: 20px;
  font-size: 1.9em;
}

p {
  margin: 10px;
  padding: 10px;
}
</style>