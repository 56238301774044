<template>
  <ion-card>
    <ion-card-header>
  <ion-card-title class="ion-text-center">
    <ion-text color="primary"
      ><h2 class="ion-text-wrap">
        Individuallösungen aus einer Hand mit Expertise und Erfahrung
      </h2></ion-text
    >
  </ion-card-title>

  </ion-card-header>
<ion-card-content>
  <ion-grid>
    <ion-row class="ion-align-items-start ion-justify-content-center">
         <ion-col
        size="6"
        size-xs="12"
        size-sm="12"
        size-md="4"
        size-lg="4"
        size-xl="4"
      >
        <ion-card>
          <ion-card-title class="ion-text-center"><h3 class="ion-text-wrap">Wann beauftragen Kunden GISolutions?</h3></ion-card-title>
          <ion-card-content>
          <ul>
            <li>
              Wenn GIS-Expertise und Erfahrung in der Organisation fehlt oder
              Personalmangel herrscht.
            </li>
            <li>
              Wenn eine Begleitung von der Anforderungsanalyse bis zum Betrieb
              benötigt wird.
            </li>
            <li>
              Wenn der Auswahlprozess von Informationssystemen strukturiert und
              auf Fakten basiert umgesetzt werden soll.
            </li>
            <li>
              Wenn laufende Projekte vom Zielkurs abgekommen sind und auf Kurs
              zurückgebracht werden müssen.
            </li>
          </ul></ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col
        size="6"
        size-xs="12"
        size-sm="12"
        size-md="3"
        size-lg="3"
        size-xl="3"
      >
        <ion-card >
          <ion-img :src="beratungImgUrl" />
        </ion-card>
      </ion-col>

      <ion-col
        size="6"
        size-xs="12"
        size-sm="12"
        size-md="4"
        size-lg="4"
        size-xl="4"
      >
        <ion-card>
          <ion-card-title class="ion-text-center"> <h3>Vorteile:</h3></ion-card-title>
          <ion-card-content>
          <ul>
            <li>
              Kosten- und Zeiteinsparungen durch klare Definition der Ziele vor Beginn der Umsetzung.
            </li>
            <li>
              Zielerreichung durch die Verbindung von technischer und
              organisatorischer Expertise und Erfahrung.
            </li>
            <li>
              Erfahrung in agilem und klassischem Projektmanagement in unterschiedlichen
              Branchen von Industrie, Softwareentwicklung und Verwaltung in umfangreichen, mehrjährigen Projekten.
            </li>
            <li>
              Diplomatisches, wertschätzendes Anstoßen und Begleiten von
              organisatorischen Veränderungsprozessen, die eine
              IT-Systemeinführung mit sich bringt.
            </li>
          </ul>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  </ion-card-content>
  </ion-card>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonText,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
} from '@ionic/vue';
export default defineComponent({
  name: 'compBeratung',
  components: {
    IonImg,
    IonGrid,
    IonText,
    IonRow,
    IonCol,
    IonCard,
    IonCardTitle,
    IonCardContent,
  IonCardHeader
  },
  data() {
    return {
      beratungImgUrl: require('@/assets/img/juergen/juergen_762.jpg'),
      imgSrcSet: [
        {
          url: require('@/assets/img/juergen/juergen_200.jpg'),
          w: ' 200w',
        },
        {
          url: require('@/assets/img/juergen/juergen_354.jpg'),
          w: ' 354w',
        },
        {
          url: require('@/assets/img/juergen/juergen_471.jpg'),
          w: ' 471w',
        },
        {
          url: require('@/assets/img/juergen/juergen_568.jpg'),
          w: ' 568w',
        },
        {
          url: require('@/assets/img/juergen/juergen_661.jpg'),
          w: ' 661w',
        },
        {
          url: require('@/assets/img/juergen/juergen_762.jpg'),
          w: ' 762w',
        },
      ],
    };
  },
  methods: {
    toSrcSetString(sizesArray) {
      let concatFct = (accum, red) =>
        accum.concat(red.url).concat(' ').concat(red.w).concat(',');
      sizesArray.reduce(concatFct, '');
    },
  },
});
</script>

<style scoped>
ion-img::part(image) {
  height: 400px;
  width: 100%;
  max-width: 3000px;
  display: block;
  object-fit: cover;
}
ion-col {
  padding: 10px;
}

ion-title {
  padding: 20px;
  font-size: 1.9em;
}


ion-card-subtitle{
  font-size: 1.3em;
  padding: 10px;
}

ion-card-title{
  padding: 10px;
  font-size: 1.9em;
}

ion-card-content{
  margin: 10px;
  font-size: 1.2em;
}

p {
  font-size: 1.5em;
  margin:10px;
  padding: 10px;
}



</style>