<template>
  <ion-app id="app">

    <ion-router-outlet />
    <!-- <AppHeader /> -->
    <Sidebar />
    <FloatingBtn />
    <router-view />
    <footer>
      <!-- <cookie-law
        theme="gisolutions"
        buttonText="Zustimmen"
        buttonLink="/privacy"
        buttonLinkText="Datenschutzbestimmungen"
        buttonLinkNewTab:TRUE
        message="Für ein optimales Nutzungsverhalten der Website verwenden wir Cookies. Wenn Sie diese Seite weiter verwenden, gehen wir davon aus, dass Sie den Datenschutzbestimmungen (Nutzung von Cookies) zustimmen."
        v-on:accept="enableAnalyticsPlugin()"
      ></cookie-law> -->
    </footer>
    <AppFooter />

  </ion-app>
</template>

<!-- <script lang="ts"> -->
<script>

import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
// import AppHeader from './components/layout/AppHeader';
import AppFooter from './components/layout/AppFooter';
import FloatingBtn from './components/layout/FloatingBtn';
// import Sidebar from './components/layout/Sidebar';
// import CookieLaw from 'vue-cookie-law';
import { bootstrap } from 'vue-gtag';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    // AppHeader,
    // CookieLaw,
    AppFooter,
    FloatingBtn,
    // Sidebar
  },
  mounted() {
    const anchor = this.$router.currentRoute.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
    // document
    // .getElementsByClassName('v-navigation-drawer__border')
    // .forEach(function (e) {
    //   e.remove();
    // });
  },
  methods: {
    enableAnalyticsPlugin() {
      // console.log(`activate tracking`);
      bootstrap().then(() => {
        // all done!
      });
    },
  },
  metaInfo: {
    // Children can override the title.
    title: 'OpenGIS as a Service',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← GISolutions',
    // Define meta tags here.
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'description',
        content:
          'Wo Ihre L&ouml;sung gedacht und gebaut wird, zuhause ist und Ihnen Zeit erspart!',
      },
      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'OpenGIS as a Service ← GISolutions' },
      { property: 'og:site_name', content: 'GISolutions' },
      { property: 'og:locale', content: 'de_DE' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://www.gisolutions.at/' },
      {
        property: 'og:image',
        content: require('@/assets/img/opengis/OpenGIS_435.png'),
      },

      // Often the same as your meta description, but not always.
      {
        property: 'og:description',
        content:
          'Wo Ihre L&ouml;sung gedacht und gebaut wird, zuhause ist und Ihnen Zeit erspart!',
      },

      // Twitter card
      { name: 'twitter:card', content: 'summary' },
      {
        name: 'twitter:site',
        content: require('@/assets/img/opengis/OpenGIS_435.png'),
      },
      { name: 'twitter:title', content: 'OpenGIS as a Service ← GISolutions' },
      {
        name: 'twitter:description',
        content:
          'Wo Ihre L&ouml;sung gedacht und gebaut wird, zuhause ist und Ihnen Zeit erspart!',
      },
      // Your twitter handle, if you have one.
      { name: 'twitter:creator', content: '@hahnjuergen' },
      {
        name: 'twitter:image:src',
        content: require('@/assets/img/opengis/OpenGIS_435.png'),
      },

      // Google / Schema.org markup:
      { itemprop: 'name', content: 'OpenGIS as a Service ← GISolutions' },
      {
        itemprop: 'description',
        content:
          'Wo Ihre L&ouml;sung gedacht und gebaut wird, zuhause ist und Ihnen Zeit erspart!',
      },
      {
        itemprop: 'image',
        content: require('@/assets/img/opengis/OpenGIS_435.png'),
      },
      { itemprop: 'addressLocality', content: 'Wien' },
      { itemprop: 'addressRegion', content: 'Wien' },
      { itemprop: 'addressCountry', content: 'Wien' },
      { itemprop: 'postalCode', content: '1140' },
      { itemprop: 'name', content: 'GISolutions' },
      { itemprop: 'telephone', content: '06645531682' },
      { itemprop: 'email', content: 'office@gisolutions.at' },
    ],
    link: [{ rel: 'canonical', href: 'https://www.gisolutions.at/index.html' }],
  },


});
</script>

<style>
  * {
    font-family: Thasadith, sans-serif !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
  font-family: Thasadith, sans-serif !important;
  line-height: 1.4;
  }
  
  /* .text-center {
  text-align: center;
  font-weight: 300;
  font-size: 1.75rem;
}

.h1-title {
  padding-top: 2rem;
  color: teal;
  font-weight: 600;
}

.h2-title {
  color: teal;
  padding: 1.5rem 0;
  font-weight: 600;
  font-size: 2rem;
}

.h3-title {
  color: teal;
  padding: 1.25rem 0;
  font-weight: 600;
  font-size: 1.5rem;
}

.p-small-text {
  font-size: 1.25rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.p-main-text {
  font-size: 1.5rem;
  font-weight: 600;
}

#sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0;
}

.Cookie--gisolutions {
  background: gray;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
  padding: 1.25em;
  color: white;
  font-weight: bolder;
}

.Cookie__buttons {
  display: flex;
  flex-direction: row;
}

.Cookie__declineButton {
  cursor: pointer;
  align-self: center;
  white-space: nowrap;
}

.Cookie--gisolutions .Cookie__button {
  color: white;
  background: #2aa198;
  padding: 0.625em 3.125em;
}
.Cookie--gisolutions div.Cookie__button:hover {

  background: #2aa198;
}

.slideFromBottom-enter, .slideFromBottom-leave-to {
    transform: translate(0px, 12.500em);
  }
  .slideFromBottom-enter-to, .slideFromBottom-leave {
    transform: translate(0px, 0px);
  }
  .slideFromBottom-enter-active,
  .slideFromBottom-leave-active,
  .slideFromTop-enter-active,
  .slideFromTop-leave-active {
    transition: transform .4s ease-in;
  }

  a {
    text-decoration: none;
  }

  /* Tablet */
  /* @media only screen and (min-width: 600px) and (max-width: 1024px) {
    .h2-title {
      font-size: 1.5rem;
    }
    .h3-title {
      font-size: 1.2rem;
    }
    .p-main-text {
      font-size: 1.3rem;
    }
    .p-small-text {
      font-size: 1.15rem;
    }

  } */

  /* Smartphones */
  /* @media only screen and (max-width: 600px) {
    .h2-title {
      font-size: 1.3rem;
       padding: 0 12% 1rem 7%;
    }
    .h3-title {
      font-size: 1.1rem;
    }
    .p-small-text {
      font-size: 1rem;
      font-weight: 600;
    }
    .p-main-text {
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
      padding: 0 12% 0 7%;
    }
  }  */
</style>