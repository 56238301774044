<template>
  <div id="privacy">
    <h2 class="h2-title">
      Erklärung der Informationspflicht (Datenschutzerklärung)
    </h2>
    <p>
      Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir
      verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen
      Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen
      informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im
      Rahmen unserer Website.
    </p>
    <p>
      Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der
      Sitzung für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt
      und stellt damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO
      dar.
    </p>

    <h3 class="h3-title">Kontakt mit uns</h3>
    <p>
      Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns
      aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage
      und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert.
      Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
    </p>

    <h3 class="h3-title">Cookies</h3>
    <p>
      Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um
      kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt
      werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser
      Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem
      Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren
      Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht
      wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das
      Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei
      der Deaktivierung von Cookies kann die Funktionalität unserer Website
      eingeschränkt sein.
    </p>

    <h3 class="h3-title">Web-Analyse</h3>
    <p>
      Diese Website nutzt aufgrund unserer berechtigten Interessen zur
      Optimierung und Analyse unseres Online-Angebots im Sinne des Art. 6 Abs. 1
      lit. f. DSGVO den Dienst „Google Analytics“, welcher von der Google Inc.
      (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird.
      Der Dienst (Google Analytics) verwendet „Cookies“ – Textdateien, welche
      auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten
      Informationen werden im Regelfall an einen Google-Server in den USA
      gesandt und dort gespeichert.
    </p>
    <p>
      Google LLC hält das europäische Datenschutzrecht ein und ist unter dem
      Privacy-Shield-Abkommen zertifiziert:
      <a
        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
        >https://www.privacyshield.gov/</a
      >
      Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer
      wird innerhalb der Mitgliedsstaaten der EU und des Europäischen
      Wirtschaftsraum und in den anderen Vertragsstaaten des Abkommens gekürzt.
      Nur in Einzelfällen wird die IP-Adresse zunächst ungekürzt in die USA an
      einen Server von Google übertragen und dort gekürzt. Durch diese Kürzung
      entfällt der Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte
      IP-Adresse des Nutzers wird nicht mit anderen von Google gespeicherten
      Daten kombiniert.
    </p>
    <p>
      Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche wir als
      Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese
      mithilfe der gesammelten Informationen eine Auswertung der Websitenutzung
      und der Websiteaktivität und erbringt mit der Internetnutzung verbundene
      Dienstleistungen. Die von Google in unserem Auftrag erhobenen Daten werden
      genutzt, um die Nutzung unseres Online-Angebots durch die einzelnen Nutzer
      auswerten zu können, z. B. um Reports über die Aktivität auf der Website
      zu erstellen, um unser Online-Angebot zu verbessern.
    </p>
    <p>
      Sie haben die Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät zu
      verhindern, indem Sie in Ihrem Browser entsprechende Einstellungen
      vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser
      Website ohne Einschränkungen zugreifen können, wenn Ihr Browser keine
      Cookies zulässt. Weiterhin können Sie durch ein Browser-Plugin verhindern,
      dass die durch Cookies gesammelten Informationen (inklusive Ihrer
      IP-Adresse) an die Google Inc. gesendet und von der Google Inc. genutzt
      werden. Folgender Link führt Sie zu dem entsprechenden Plugin:
      <a href="https://tools.google.com/dlpage/gaoptout?hl=de"
        >https://tools.google.com/</a
      >
    </p>

    <h3 class="h3-title">Nutzung von Social-Media-Plugins von Facebook/Instagram</h3>
    <p>
      Aufgrund unseres berechtigten Interesses an der Analyse, Optimierung und
      dem Betrieb unseres Online-Angebotes (im Sinne des Art. 6 Abs. 1 lit. f.
      DSGVO), verwendet diese Website das Facebook-Social-Plugin, welches von
      der Facebook Inc. (1 Hacker Way, Menlo Park, California 94025, USA)
      betrieben wird. Erkennbar sind die Einbindungen an dem Facebook-Logo bzw.
      an den Begriffen „Like“, „Gefällt mir“, „Teilen“ in den Farben Facebooks
      (Blau und Weiß). Informationen zu allen Facebook-Plugins finden Sie über
      den folgenden Link:
      <a href="https://developers.facebook.com/docs/plugins/"
        >https://developers.facebook.com/docs/plugins/</a
      >
      Facebook Inc. hält das europäische Datenschutzrecht ein und ist unter dem
      Privacy-Shield-Abkommen zertifiziert:
      <a
        href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
        >https://www.privacyshield.gov/</a
      >
      Das Plugin stellt eine direkte Verbindung zwischen Ihrem Browser und den
      Facebook-Servern her. Der Websitebetreiber hat keinerlei Einfluss auf die
      Natur und den Umfang der Daten, welche das Plugin an die Server der
      Facebook Inc. übermittelt. Informationen dazu finden Sie hier:
      <a href="https://www.facebook.com/help/186325668085084"
        >https://www.facebook.com/</a
      >
      Das Plugin informiert die Facebook Inc. darüber, dass Sie als Nutzer diese
      Website besucht haben. Es besteht hierbei die Möglichkeit, dass Ihre
      IP-Adresse gespeichert wird. Sind Sie während des Besuchs auf dieser
      Website in Ihrem Facebook-Konto eingeloggt, werden die genannten
      Informationen mit diesem verknüpft. Nutzen Sie die Funktionen des Plugins
      – etwa indem Sie einen Beitrag teilen oder „liken“ –, werden die
      entsprechenden Informationen ebenfalls an die Facebook Inc. übermittelt.
      Möchten Sie verhindern, dass die Facebook. Inc. diese Daten mit Ihrem
      Facebook-Konto verknüpft, loggen Sie sich bitte vor dem Besuch dieser
      Website bei Facebook aus und löschen Sie die gespeicherten Cookies. Über
      Ihr Facebook-Profil können Sie weitere Einstellungen zur Datenverarbeitung
      für Werbezwecke tätigen oder der Nutzung Ihrer Daten für Werbezwecke
      widersprechen. Zu den Einstellungen gelangen Sie hier: Profileinstellungen
      bei Facebook:
      <a
        href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
        >https://www.facebook.com/ads</a
      >
      Cookie-Deaktivierungsseite der US-amerikanischen Website:
      <a href="http://optout.aboutads.info/?c=2#!/"
        >http://optout.aboutads.info</a
      >
      Cookie-Deaktivierungsseite der europäischen Website:
      <a href="http://optout.networkadvertising.org/?c=1#!/"
        >http://optout.networkadvertising.org</a
      >
      Welche Daten, zu welchem Zweck und in welchem Umfang Facebook Daten
      erhebt, nutzt und verarbeitet und welche Rechte sowie
      Einstellungsmöglichkeiten Sie zum Schutz Ihrer Privatsphäre haben, können
      Sie in den Datenschutzrichtlinien von Facebook nachlesen. Diese finden Sie
      hier:
      <a href="https://www.facebook.com/about/privacy/"
        >https://www.facebook.com/about/</a
      >
    </p>

    <h3 class="h3-title">Datenschutzerklärung für unsere Social Media Seiten</h3>
    <p>
      Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gem. Art. 13
      Datenschutzgrundverordnung (DSGVO) informieren.
    </p>
    <h3 class="h3-title">Verantwortlicher</h3>
    <p>
      Jürgen Hahn, GISolutions e.U. betreibt folgende Social Media Seiten:
      <ul>
        <li>
      Twitter:
      <a href="https://twitter.com/solutions_gi"
        >https://twitter.com/solutions_gi</a
      ></li>
      <li>
      LinkedIn:
      <a href="https://www.linkedin.com/company/gisolutions-e-u"
        >https://www.linkedin.com/company/gisolutions-e-u</a
      ></li>
      </ul>
    </p>
    <p>
      Neben uns gibt es noch den Betreiber der Social Media Plattform an sich.
      Auch dieser ist insofern ein weiterer Verantwortlicher, der eine
      Datenverarbeitung durchführt, auf die wir jedoch nur begrenzten Einfluss
      haben. An den Stellen, an denen wir Einfluss nehmen und die
      Datenverarbeitung parametrisieren können, wirken wir im Rahmen der uns zur
      Verfügung stehenden Möglichkeiten auf den datenschutzgerechten Umgang
      durch den Betreiber der Social Media Plattform hin. An vielen Stellen
      können wir die Datenverarbeitung durch den Betreiber der Social Media
      Plattform aber nicht beeinflussen und wissen auch nicht genau, welche
      Daten dieser verarbeitet.
    </p>
    <h3 class="h3-title">Datenverarbeitung durch uns</h3>
    <p>
      Die von Ihnen auf unseren Social Media Seiten eingegebenen Daten wie z. B.
      Kommentare, Videos, Bilder, Likes, öffentliche Nachrichten etc. werden
      durch die Social Media Plattform veröffentlicht und von uns zu keiner Zeit
      für andere Zwecke verwendet oder verarbeitet. Wir behalten uns lediglich
      vor, Inhalte zu löschen, sofern dies erforderlich sein sollte. Ggf. teilen
      wir Ihre Inhalte auf unserer Seite, wenn dies eine Funktion der Social
      Media Plattform ist und kommunizieren über die Social Media Plattform mit
      Ihnen. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die
      Datenverarbeitung erfolgt im Interesse unserer Öffentlichkeitsarbeit und
      Kommunikation. Wenn Sie einer bestimmten Datenverarbeitung, auf die wir
      einen Einfluss haben, widersprechen möchten, wenden Sie sich bitte an
      Jürgen Hahn. Wir prüfen Ihren Widerspruch dann.
    </p>

    <p>
      Sofern Sie uns eine Anfrage auf der Social Media Plattform stellen,
      verweisen wir je nach der erforderlichen Antwort ggf. auch auf andere,
      sichere Kommunikationswege, die Vertraulichkeit garantieren. Sie haben
      immer die Möglichkeit, uns vertrauliche Anfragen an unsere Anschrift zu
      schicken. Wie bereits ausgeführt, achten wir an den Stellen, an denen uns
      der Anbieter der Social Media Plattform die Möglichkeit gibt, darauf,
      unsere Social Media Seiten möglichst datenschutzkonform zu gestalten.
    </p>

    <p>
      Wir nutzen die Social Media Plattformen für Werbezwecke. Dabei greifen wir
      auch auf die demografischen, interessenbasierten, verhaltensbasierten oder
      standortbasierten Zielgruppendefinitionen für Werbung zurück, die uns der
      Betreiber der jeweiligen Social Media Plattform zur Verfügung stellt.
      Nähere Informationen zur Datenerfassung und Datenverarbeitung auf den
      Social Media Plattformen sowie dem dortigen Schutz Ihrer Privatsphäre
      finden Sie in den unten aufgeführten Datenschutzerklärungen der jeweiligen
      Anbieter. Im Hinblick auf Statistiken, die uns der Anbieter der Social
      Media Plattform zur Verfügung stellt, können wir diese nur bedingt
      beeinflussen und auch nicht abschalten. Wir achten aber darauf, dass uns
      keine zusätzlichen optionalen Statistiken zur Verfügung gestellt werden,
      die personenbezogene Daten verarbeiten. Für einzelne Social Media Kanäle
      nutzen wir Conversion-Tracking-Möglichkeiten. Dafür haben wir
      entsprechende Pixel bzw. Tags auf unserer Webseite eingebunden, um
      Conversion-Daten zu erfassen.
    </p>

    <h3 class="h3-title">Datenverarbeitung durch den Betreiber der Social Media Plattform</h3>
    <p>
      Der Betreiber der Social Media Plattform setzt Webtrackingmethoden ein.
      Das Webtracking kann dabei auch unabhängig davon erfolgen, ob Sie bei der
      Social Media Plattform angemeldet oder registriert sind. Wie bereits
      dargestellt können wir auf die Webtrackingmethoden der Social Media
      Plattform leider kaum Einfluss nehmen. Wir können dieses z.B. nicht
      abschalten. Bitte seien Sie sich darüber bewusst: Es kann nicht
      ausgeschlossen werden, dass der Anbieter der Social Media Plattform Ihre
      Profil- und Verhaltensdatendaten nutzt, etwa um Ihre Gewohnheiten,
      persönlichen Beziehungen, Vorlieben usw. auszuwerten. Wir haben insofern
      keinen Einfluss auf die Verarbeitung Ihrer Daten durch den Anbieter der
      Social Media Plattform. Nähere Informationen zur Datenverarbeitung durch
      den Anbieter der Social Media Plattform und weitere
      Widerspruchsmöglichkeiten finden Sie in der Datenschutzerklärung des
      Anbieters: 
      <ul>
        <li>
      Twitter: <a href='https://twitter.com/de/privacy'>https://twitter.com/de/privacy</a> </li>
      <li>
      Facebook: <a href='https://www.facebook.com/privacy/explanation'>https://www.facebook.com/privacy/explanation</a> </li>
       <li>
       YouTube: <a href='https://policies.google.com/privacy?hl=de'>https://policies.google.com/privacy?hl=de</a> </li>
      <li>
       Google+: <a href='https://policies.google.com/privacy?hl=de'>https://policies.google.com/privacy?hl=de</a> </li>
      <li>
       LinkedIn: <a href='https://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv'>https://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv</a> </li>
      <li>
       XING: <a href='https://privacy.xing.com/de/datenschutzerklaerung'>https://privacy.xing.com/de/datenschutzerklaerung</a> </li>
      </ul>
    </p>

    <h3 class="h3-title">Ihre Rechte</h3>
    <p>
     Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der uns juergen.hahn@gisolutions.at oder der Datenschutzbehörde beschweren. Sie erreichen uns unter folgenden Kontaktdaten:
    </p>
    <hr>
    <h3 style="color: teal; padding-top: 1rem;">GISolutions e.U</h3>
    <ul>
      <li>Adresse : Hadikgasse 78, Top 2</li>
      <li>A - 1140 | Wien</li>
      <li>Mobile : +43 664 55 31 682</li>
      <li>E-mail : office@gisolutions.at</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "compPrivacy",
};
</script>

<style scoped>
#privacy {
  display: flex;
  flex-direction: column;
  padding: 4.5rem 4rem 3rem 3rem;
}
#privacy p {
  opacity: 0.9;
}

#privacy ul li {
  padding: 0.25rem;
  list-style: none;
  margin-left: 1rem;
}

/* Tablet */
@media (min-width: 600px) and (max-width: 1024px) {

}

/* Smartphones */
@media (max-width: 500px) {

}
</style>